<template>
  <div class="sellersList">
    <div class="nav-tabs">
      <a
        href="#"
        v-for="tab in tabs"
        :key="tab.id"
        @click="changeView(tab.id)"
        class="item"
        :class="{ active: view == tab.id }"
        >{{ tab.label }}</a
      >
    </div>
    <template v-if="isLoading">
      <content-loader
        :height="100"
        :width="400"
        :speed="2"
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
      >
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="14" />
        <rect x="0" y="22" rx="0" ry="0" width="100%" height="14" />
        <rect x="0" y="44" rx="0" ry="0" width="100%" height="14" />
        <rect x="0" y="64" rx="0" ry="0" width="100%" height="14" />
        <rect x="0" y="86" rx="0" ry="0" width="100%" height="14" />
      </content-loader>
    </template>
    <template v-if="!isLoading">
      <div
        class="tabs-content"
        :class="{ loading: updateLoading }"
        v-if="allItems.length > 0"
      >
        <div class="loading-overlay" v-if="updateLoading">
          <font-awesome-icon :icon="['fas', 'circle-notch']" spin />
        </div>
        <div class="table-head">
          <div class="order"></div>
          <div class="name">
            <strong class="clickable" @click="sortParam = 'name'">
              {{ $t("list.title_col") }}
              <font-awesome-icon
                v-if="sortParam == 'name'"
                :icon="['fa', 'caret-down']"
              />
            </strong>
          </div>
          <div class="note">
            <strong>{{ $t("list.note_col") }}</strong>
          </div>
          <div class="type">
            <strong class="clickable" @click="sortParam = 'type'">
              {{ $t("list.type_col") }}
              <font-awesome-icon
                v-if="sortParam == 'type'"
                :icon="['fa', 'caret-down']"
              />
            </strong>
          </div>
          <div class="status">
            <strong>{{ $t("list.status_col") }}</strong>
          </div>
          <div class="added">
            <strong class="clickable" @click="sortParam = 'added'">
              {{ $t("list.added_col") }}
              <font-awesome-icon
                v-if="sortParam == 'added'"
                :icon="['fa', 'caret-down']"
              />
            </strong>
          </div>
          <div class="rate">
            <strong>{{ $t("list.rate_col") }}</strong>
          </div>
          <div class="controls">
            <strong>{{ $t("list.actions_col") }}</strong>
          </div>
        </div>
        <div
          class="table-row"
          v-for="(item, i) in filteredList"
          :key="item.uid"
          :class="{ disabled: item.status == 0 }"
        >
          <div class="order">{{ i + 1 }}</div>
          <div class="name">
            <span :class="{ keyword: item.type == 'keyword' }">{{
              item.name
            }}</span>
          </div>
          <div class="note">
            <font-awesome-icon
              :class="'note-icon'"
              v-tooltip="'Add note'"
              @click="noteModal(item.uid, item.name, item.type, item.note)"
              v-if="!item.note"
              :icon="['fa', 'sticky-note']"
            />
            <font-awesome-icon
              :class="'info-icon'"
              v-tooltip="item.note"
              @click="noteModal(item.uid, item.name, item.type, item.note)"
              v-if="item.note"
              :icon="['fa', 'info-circle']"
            />
          </div>
          <div class="type">
            <span>{{ item.type }}</span>
            <strong v-if="item.look_for_modified"> M </strong>
          </div>
          <div class="status">
            <div class="ui-checkbox">
              <label>
                <input
                  @change="
                    updateUserKeywords(fetchedKeywords)
                      ? item.type == 'keyword'
                      : updateUserSellers(fetchedSellers)
                  "
                  type="checkbox"
                  :checked="item.status == 1"
                  v-model="item.status"
                  :disabled="isLoading"
                  true-value="1"
                  false-value="0"
                />
                <span>
                  <i></i>
                </span>
              </label>
            </div>
          </div>
          <div class="added">
            {{ item.added | dateAdded | moment("from", "now") }}
          </div>
          <div class="rate">
            <span>
              <font-awesome-icon :icon="['fa', 'stopwatch']" />
              {{ item.rate }} {{ $t("global.sec") }}
            </span>
          </div>
          <div class="controls">
            <a
              href="#"
              class="edit"
              v-if="item.type == 'keyword'"
              v-tooltip="'Edit keyword'"
              @click.prevent="openAddingModal('keyword', 'edit', item.uid)"
            >
              <font-awesome-icon :icon="['fa', 'pencil-alt']" />
            </a>
            <a
              href="#"
              class="clone"
              v-if="item.type == 'keyword'"
              v-tooltip="'Clone keyword'"
              @click.prevent="openAddingModal('keyword', 'clone', item.uid)"
            >
              <font-awesome-icon :icon="['far', 'clone']" />
            </a>
            <a
              v-bind:href="'https://www.ebay.com/usr/' + item.name"
              v-tooltip="'Open in new tab'"
              class="link"
              target="_blank"
              v-if="item.type == 'seller'"
            >
              <font-awesome-icon :icon="['fas', 'external-link-alt']" />
            </a>
            <a
              href="#"
              class="remove"
              v-tooltip="'Remove'"
              @click.prevent="openRemovingModal(item)"
            >
              <font-awesome-icon :icon="['fa', 'trash']" />
            </a>
          </div>
        </div>
      </div>
      <template v-if="noNotifications">
        <no-items-notifications />
      </template>
      <template v-if="noItems">
        <no-items />
      </template>

      <div class="tabs-controls">
        <template v-if="!isLoading && view == 'all'">
          <button-primary
            :onClick="
              () => {
                addContextOpen = !addContextOpen;
              }
            "
            :class="'small'"
            :disabled="
              user.profile &&
              user.profile.telegram &&
              user.profile.telegram !== 'true' &&
              !hasItems
            "
          >
            + {{ $t("list.btn_add") }}
            <font-awesome-icon :icon="['fa', 'caret-down']" />
            <context-menu v-show="addContextOpen == true" :position="'left'">
              <a
                href
                @click.prevent="
                  () => {
                    openAddingModal('seller');
                  }
                "
                >{{ $t("list.btn_add_seller") }}</a
              >
              <a
                href
                @click.prevent="
                  () => {
                    openAddingModal('keyword', 'add');
                  }
                "
                >{{ $t("list.btn_add_keyword") }}</a
              >
            </context-menu>
          </button-primary>
        </template>
        <template v-if="!isLoading && view == 'seller'">
          <button-primary
            :onClick="
              () => {
                openAddingModal('seller');
              }
            "
            :class="'small'"
            :disabled="
              user.profile &&
              user.profile.telegram &&
              user.profile.telegram !== 'true' &&
              !hasItems
            "
            >+ {{ $t("list.btn_add_seller") }}</button-primary
          >
        </template>
        <template v-if="!isLoading && view == 'keyword'">
          <button-primary
            :onClick="
              () => {
                openAddingModal('keyword', 'add');
              }
            "
            :class="'small'"
            :disabled="
              user.profile &&
              user.profile.telegram &&
              user.profile.telegram !== 'true' &&
              !hasItems
            "
            >+ {{ $t("list.btn_add_keyword") }}</button-primary
          >
        </template>
      </div>
    </template>

    <addSellerModal
      @saveSeller="saveSeller"
      ref="addSellerModal"
      :updateLoading="updateLoading"
      :categories="processedCategories"
    />
    <upgradeModal :show="showReachedLimit" @close="closeLimitModal" />

    <removeItemModal
      :show="openRemoveModal"
      :itemToRemove="itemToRemove"
      :updateLoading="updateLoading"
      @confirm="removeItem()"
      @cancel="openRemoveModal = false"
    />

    <div class="modal modal__keyword" v-if="openAddKeywordModal.status == true">
      <div class="modal__wrapper">
        <div class="card">
          <div class="modal__title">
            <template v-if="openAddKeywordModal.action == 'add'"
              >+ {{ $t("list.btn_add_keyword") }}</template
            >
            <template v-if="openAddKeywordModal.action == 'edit'">
              <font-awesome-icon :icon="['fa', 'pencil-alt']" />
              {{ $t("adding.edit") }} {{ $t("adding.keyword") }}
            </template>
            <template v-if="openAddKeywordModal.action == 'clone'">
              <font-awesome-icon :icon="['far', 'clone']" />
              {{ $t("adding.clone") }} {{ $t("adding.keyword") }}
            </template>
          </div>
          <div class="modal__text">
            <div class="form-group">
              <label>{{ $t("adding.enter_keyword") }}</label>
              <input
                pattern="[A-Za-z0-9\,\-]{1,30}"
                type="text"
                ref="keywordInput"
                v-model="newKeyword.name"
              />
            </div>

            <div class="form-group">
              <label
                >{{ $t("adding.exclude_keywords") }} ({{
                  $t("adding.optional")
                }})</label
              >
              <vue-tags-input
                :add-on-key="[13, ':', ';', ',']"
                :placeholder="$t('adding.type_and_press_enter')"
                v-model="currentStopWord"
                :tags="newKeyword.stopwords"
                @tags-changed="(newTags) => updateStopWords(newTags)"
              />
            </div>
            <div class="form-group">
              <label>{{ $t("adding.category") }}</label>
              <treeselect
                v-model="newKeyword.categories"
                :multiple="false"
                :options="processedCategories"
                :searchable="false"
                :noChildrenText="'No child categories'"
              />
            </div>
            <div class="form-group">
              <label>{{ $t("adding.interval") }}</label>
              <select
                name=""
                id=""
                v-model="newKeyword.rate"
                :disabled="newKeyword.isFast == true"
              >
                <option
                  v-for="r in rates"
                  :value="r"
                  :key="r"
                  :disabled="!checkIfKeywordRateAllowed(r)"
                  :selected="newKeyword.rate == r"
                >
                  <template v-if="r < 60">{{ r }} s.</template>
                  <template v-else> {{ r / 60 }} min.</template>
                </option>
              </select>
            </div>
            <div class="form-group" v-if="plan && allowFast">
              <label
                >⚡{{ $t("adding.fast_keyword") }} ({{
                  plan.fast_keywords_rate
                }}s.)</label
              >
              <div class="ui-checkbox">
                <label>
                  <input
                    type="checkbox"
                    :checked="newKeyword.isFast"
                    @change="toggleFastKeyword()"
                  />
                  <span>
                    <i></i>
                  </span>
                </label>
              </div>
            </div>
            <div class="form-group">
              <label>{{ $t("adding.checkout_link") }}</label>
              <div class="ui-checkbox">
                <label>
                  <input
                    type="checkbox"
                    v-model="newKeyword.includeQuickBuyLink"
                  />
                  <span>
                    <i></i>
                  </span>
                </label>
              </div>
            </div>
            <div class="form-group" v-if="plan && plan.buyButton == true">
              <label>Buy button</label>
              <div class="ui-checkbox">
                <label>
                  <input type="checkbox" v-model="newKeyword.buyButton" />
                  <span>
                    <i></i>
                  </span>
                </label>
              </div>
            </div>
            <div class="form-group">
              <label>{{ $t("adding.all_images") }}</label>
              <div class="ui-checkbox">
                <label>
                  <input type="checkbox" v-model="newKeyword.allImages" />
                  <span>
                    <i></i>
                  </span>
                </label>
              </div>
            </div>
            <div class="form-group" v-show="!newKeyword.allImages">
              <label>{{ $t("adding.extra_images") }} </label>
              <div class="ui-radio-group">
                <label v-for="(n, i) in 4" :key="i">
                  <input
                    type="radio"
                    :value="i"
                    checked
                    v-model="newKeyword.extraImages"
                  />
                  <span>{{ i }}</span>
                </label>
              </div>
            </div>

            <div class="form-group">
              <label>{{ $t("adding.buying_format") }}</label>
              <div class="ui-radio-group">
                <label>
                  <input
                    type="radio"
                    value="0"
                    checked
                    v-model="newKeyword.buyingFormat"
                  />
                  <span>{{ $t("adding.all") }}</span>
                </label>
                <label>
                  <input
                    type="radio"
                    value="1"
                    v-model="newKeyword.buyingFormat"
                  />
                  <span>Auction</span>
                </label>
                <label>
                  <input
                    type="radio"
                    value="2"
                    v-model="newKeyword.buyingFormat"
                  />
                  <span>Buy It Now</span>
                </label>
                <label>
                  <input
                    type="radio"
                    value="3"
                    v-model="newKeyword.buyingFormat"
                  />
                  <span>Best Offer</span>
                </label>
              </div>
            </div>

            <div class="form-group">
              <label>{{ $t("adding.condition") }}</label>
              <div class="ui-radio-group">
                <label>
                  <input
                    type="radio"
                    value="0"
                    checked
                    v-model="newKeyword.condition"
                  />
                  <span>{{ $t("adding.any") }}</span>
                </label>
                <label>
                  <input
                    type="radio"
                    value="1000"
                    v-model="newKeyword.condition"
                  />
                  <span>New</span>
                </label>
                <label>
                  <input
                    type="radio"
                    value="3000"
                    v-model="newKeyword.condition"
                  />
                  <span>Used</span>
                </label>
                <label>
                  <input
                    type="radio"
                    value="7000"
                    v-model="newKeyword.condition"
                  />
                  <span>Parts</span>
                </label>
              </div>
            </div>
            <div class="form-group keyword-price">
              <div class="w-50">
                <label>
                  {{ $t("adding.price") }} - $
                  <strong>[{{ $t("adding.min") }}]</strong>
                </label>
                <input
                  type="tel"
                  v-model="newKeyword.priceMin"
                  placeholder="0"
                />
              </div>
              <div class="w-50">
                <label>
                  {{ $t("adding.price") }} - $
                  <strong>[{{ $t("adding.max") }}]</strong>
                </label>
                <input
                  type="tel"
                  v-model="newKeyword.priceMax"
                  :placeholder="$t('adding.any_f')"
                />
              </div>
            </div>
          </div>
          <div class="modal__controls">
            <button-primary
              :onClick="closeAddingModal"
              :class="'small secondary black'"
              >{{ $t("adding.cancel") }}</button-primary
            >
            <button-primary
              :onClick="
                () => {
                  addKeyword(openAddKeywordModal.action);
                }
              "
              :disabled="newKeyword.name.length < 2"
              :class="'small'"
            >
              <span v-if="!updateLoading">{{ $t("adding.save") }}</span>
              <font-awesome-icon
                v-if="updateLoading == true"
                :icon="['fas', 'circle-notch']"
                spin
              />
            </button-primary>
          </div>
        </div>
      </div>
    </div>
    <!-- /addingKeywordModal -->

    <div class="modal modal_note" v-if="openNoteModal == true">
      <div class="modal_wrapper">
        <div class="card">
          <div class="modal__title">
            {{ $t("adding.note") }} {{ $t("adding.to") }} {{ note.type }}:
            {{ note.name }}
          </div>
          <div class="modal__text">
            <textarea
              :maxlength="note.maxlength"
              v-model="note.text"
              rows="5"
            ></textarea>
            <span
              class="characters-left"
              :class="{
                error: note.text.length == note.maxlength,
              }"
              >{{ $t("adding.characters") }} :
              {{ note.maxlength - note.text.length }}</span
            >
          </div>
          <div class="modal__controls">
            <button-primary
              :onClick="
                () => {
                  closeNoteModal();
                }
              "
              :class="'small secondary black'"
              >{{ $t("adding.cancel") }}</button-primary
            >
            <button-primary
              v-if="note.remove == true"
              :onClick="
                () => {
                  removeNote(note.type, note.uid);
                }
              "
              :class="'small delete'"
            >
              <span v-if="!updateLoading">{{ $t("adding.remove") }}</span>
              <font-awesome-icon
                v-if="updateLoading == true"
                :icon="['fas', 'circle-notch']"
                spin
              />
            </button-primary>
            <button-primary
              :onClick="
                () => {
                  saveNote(note.type, note.uid);
                }
              "
              :disabled="note.text.length < 3"
              :class="'small'"
            >
              <span v-if="!updateLoading">{{ $t("adding.save") }}</span>
              <font-awesome-icon
                v-if="updateLoading == true"
                :icon="['fas', 'circle-notch']"
                spin
              />
            </button-primary>
          </div>
        </div>
      </div>
    </div>
    <!-- adding note modal -->
  </div>
</template>
<script>
import firebase from "firebase/app";
import "firebase/auth"; // for authentication
import "firebase/storage"; // for storage
import "firebase/firestore"; // for cloud firestore
import "firebase/functions"; // for cloud functions
import { ContentLoader } from "vue-content-loader";
import { mapGetters } from "vuex";
import VueTagsInput from "@johmun/vue-tags-input";
import Treeselect from "@riophae/vue-treeselect";

import ebayCategoriesData from "@/assets/data/categories.json";

import noItemsNotifications from "@/components/no-items-notifications.vue";
import noItems from "@/components/no-items.vue";

//MODALS
import addSellerModal from "@/components/modals/add-seller.vue";
import upgradeModal from "@/components/modals/upgrade-plan.vue";
import removeItemModal from "@/components/modals/remove-item.vue";

export default {
  components: {
    ContentLoader,
    VueTagsInput,
    Treeselect,
    addSellerModal,
    upgradeModal,
    removeItemModal,
    noItemsNotifications,
    noItems,
  },
  data() {
    return {
      count: 0,
      openRemoveModal: false,
      openAddSellerModal: false,
      openNoteModal: false,
      updateLoading: false,

      // itemToRemove: 900,
      ebayCategories: ebayCategoriesData,
      processedCategories: [],
      openAddKeywordModal: {
        status: false,
        action: "add",
      },
      itemToRemove: null,
      isLoading: true,
      mappedCategories: [],

      newKeyword: {
        name: "",
        rate: 300,
        isFast: false,
        stopwords: [],
        categories: null,
        searchTitle: false,
        condition: 0,
        buyingFormat: 0,
        extraImages: 0,
        buyButton: false,
        allImages: false,
        includeQuickBuyLink: true,
        priceMin: "",
        priceMax: "",
        acceptsOffers: false,
      },
      resetNewKeyword: {
        name: "",
        rate: 300,
        isFast: false,
        stopwords: [],
        categories: null,
        searchTitle: false,
        condition: 0,
        buyingFormat: 0,
        extraImages: 0,
        buyButton: false,
        allImages: false,
        includeQuickBuyLink: true,
        priceMin: "",
        priceMax: "",
        acceptsOffers: false,
      },
      currentStopWord: "",
      catPlaceHolder: "Any",
      stopwords: "",
      addSellerError: null,
      view: "all",
      rates: [1, 10, 30, 60, 120, 180, 300, 600, 900, 1800, 3600],
      fetchedSellers: [],
      fetchedKeywords: [],
      userItemsList: [],
      addContextOpen: false,
      showReachedLimit: false,
      sortedItems: [],
      sortParam: "added",
      currentlyEditingItem: [],
      note: {
        text: "",
        maxlength: 120,
        type: "",
        name: "",
        remove: false,
      },
    };
  },
  watch: {
    newKeyword: {
      deep: true,
      handler(newVal) {
        let re = /[^\x20-\x7E]+/g;
        this.newKeyword.name = newVal.name.replace(re, "");
      },
    },
  },
  computed: {
    noItems() {
      return (
        this.allItems.length === 0 &&
        this.user.profile &&
        this.user.profile.telegram == "true"
      );
    },
    noNotifications() {
      return (
        this.user.profile &&
        this.user.profile.telegram !== "true" &&
        !this.hasItems
      );
    },
    tabs() {
      return [
        { id: "all", label: this.$t("list.all_tab") },
        { id: "seller", label: this.$t("list.sellers_tab") },
        { id: "keyword", label: this.$t("list.keywords_tab") },
      ];
    },
    allowFast() {
      var allow = false;
      var fastCount = 0;
      if (this.userKeywords) {
        for (var key in this.userKeywords) {
          if (this.userKeywords[key].isFast) {
            fastCount += 1;
          }
        }
      }
      if (
        this.plan &&
        this.plan.fast_keywords_count &&
        Number(this.plan.fast_keywords_count) > 0 &&
        fastCount < Number(this.plan.fast_keywords_count)
      ) {
        allow = true;
      }
      return allow;
    },
    hasItems() {
      var items = false;
      if (this.allItems.length) {
        items = true;
      }
      return items;
    },
    ...mapGetters({
      user: "user",
      plans: "plans",
      userSellers: "userSellers",
      userKeywords: "userKeywords",
    }),
    plan() {
      let plan = 0;
      if (this.user.profile) {
        plan = this.plans.find((el) => {
          return el.id == this.user.profile.plan;
        });
      }
      return plan;
    },
    allowedRates() {
      let allowed = [];
      allowed = this.rates.filter((el) => {
        return el >= this.plan.keywords_rate;
      });
      return allowed;
    },
    filteredList() {
      const items = this.allItems.filter((item) => {
        if (this.view == "all") {
          return item;
        } else {
          return item.type == this.view;
        }
      });
      return items.sort((a, b) => {
        if (this.sortParam == "type") {
          return b.type - a.type;
        }
        if (this.sortParam == "added") {
          return b.added.seconds - a.added.seconds;
        }
        if (this.sortParam == "name") {
          return a.name.localeCompare(b.name);
        }
      });
    },
    allItems() {
      let items = [];
      if (this.fetchedKeywords.length) {
        this.fetchedKeywords.forEach((el) => {
          items.push(el);
        });
      }
      if (this.fetchedSellers.length) {
        this.fetchedSellers.forEach((el) => {
          items.push(el);
        });
      }

      return items;
    },
  },

  filters: {
    dateAdded(data) {
      if (data.seconds) {
        return data.seconds;
      } else {
        return data;
      }
    },
  },
  methods: {
    async getSellers() {
      this.isLoading = true;
      var db = firebase.firestore();
      var docRef = db.collection("sellers").doc(this.user.data.uid);
      await docRef
        .get()
        .then((doc) => {
          let items = [];
          if (doc.data()) {
            items = doc.data().items || [];
          }
          this.fetchedSellers = items;
          this.isLoading = false;
          this.$store.dispatch("fetchUserSellers", this.fetchedSellers);
        })
        .catch((error) => {
          this.isLoading = false;
          this.fetchedSellers = error;
        });
    },
    async getKeywords() {
      this.isLoading = true;
      var db = firebase.firestore();
      var docRef = db.collection("keywords").doc(this.user.data.uid);
      await docRef
        .get()
        .then((doc) => {
          let items = [];
          if (doc.data()) {
            items = doc.data().items || [];
          }
          this.fetchedKeywords = items;
          this.isLoading = false;

          this.$store.dispatch("fetchUserKeywords", this.fetchedKeywords);
        })
        .catch((error) => {
          this.isLoading = false;
          this.fetchedKeywords = error;
        });
    },
    getItems() {
      this.getSellers();
      this.getKeywords();
    },
    updateUserKeywords(updatedKeywords) {
      this.updateLoading = true;
      var db = firebase.firestore();
      const keywordRef = db.collection("keywords").doc(this.user.data.uid);
      keywordRef
        .set({
          items: updatedKeywords,
        })
        .then(() => {
          this.updateLoading = false;
          this.openAddKeywordModal.status = false;
          this.newKeyword = this.resetNewKeyword;
          this.getItems();
        })
        .catch((error) => {
          return error;
        });
    },
    updateUserSellers(updatedSellers) {
      this.updateLoading = true;
      var db = firebase.firestore();
      const sellerRef = db.collection("sellers").doc(this.user.data.uid);
      sellerRef
        .set({
          items: updatedSellers,
        })
        .then(() => {
          this.updateLoading = false;
          this.$refs.addSellerModal.show = false;
          this.getSellers();
        })
        .catch(() => {
          this.updateLoading = false;
        });
    },
    checkIfKeywordRateAllowed(rate) {
      var allowed = true;
      if (this.allowedRates.includes(rate) == false) {
        allowed = false;
      }
      return allowed;
    },
    toggleFastKeyword() {
      this.newKeyword.isFast = !this.newKeyword.isFast;

      if (this.newKeyword.isFast == true) {
        this.newKeyword.rate = Number(this.plan.fast_keywords_rate);
      } else {
        this.newKeyword.rate = Number(this.allowedRates[0]);
      }
    },
    processCategories: function () {
      var result = [];
      var count = 0;
      for (var i = 0; i < this.ebayCategories.length; i++) {
        var item = this.catNormalizer(this.ebayCategories[i]);
        count++;
        if (this.ebayCategories[i].Children) {
          item["children"] = [];
          for (var x = 0; x < this.ebayCategories[i].Children.length; x++) {
            count++;
            var children_1 = this.catNormalizer(
              this.ebayCategories[i].Children[x]
            );

            if (this.ebayCategories[i].Children[x].Children) {
              children_1["children"] = [];
              for (
                var y = 0;
                y < this.ebayCategories[i].Children[x].Children.length;
                y++
              ) {
                count++;
                var children_2 = this.catNormalizer(
                  this.ebayCategories[i].Children[x].Children[y]
                );
                children_1.children.push(children_2);
              }
            }
            item.children.push(children_1);
          }
        }
        this.count = count;
        result.push(item);
      }
      this.processedCategories = result;
    },
    catNormalizer(node) {
      if (node.Children) {
        return {
          id: node.Id,
          label: node.Name,
          children: node.Children,
        };
      } else {
        return {
          id: node.Id,
          label: node.Name,
        };
      }
    },
    updateStopWords(newTags) {
      this.newKeyword.stopwords = [];
      for (var i = 0; i < newTags.length; i++) {
        const re = /[^\x20-\x7E]+/g;
        var item = newTags[i].text.replace(re, "");

        this.newKeyword.stopwords.push(item);
      }
    },
    noteModal(uid, name, type, text) {
      if (text.length) {
        this.note.text = text;
        this.note.remove = true;
      }
      this.note.uid = uid;
      this.note.name = name;
      this.note.type = type;
      this.openNoteModal = true;
    },
    saveNote(type, uid) {
      this.quickEdit(type, uid, true);
    },
    removeNote(type, uid) {
      this.note.text = "";
      this.note.remove = false;
      this.quickEdit(type, uid, true);
    },
    closeNoteModal() {
      this.openNoteModal = false;
      this.note.text = "";
      this.note.remove = false;
    },

    openRemovingModal(item) {
      this.openRemoveModal = true;
      this.itemToRemove = item;
    },
    closeRemovingModal() {
      this.openRemoveModal = false;
      this.sellerToRemove = null;
      this.updateLoading = false;
    },
    removeItem() {
      this.updateLoading = true;
      let type = this.itemToRemove.type;
      let updatedItems = [];
      if (type == "seller") {
        updatedItems = this.fetchedSellers.filter((el) => {
          return el.uid != this.itemToRemove.uid;
        });
        this.updateUserSellers(updatedItems);
      }
      if (type == "keyword") {
        updatedItems = this.fetchedKeywords.filter((el) => {
          return el.uid != this.itemToRemove.uid;
        });
        this.updateUserKeywords(updatedItems);
      }
      this.openRemoveModal = false;
    },
    changeView(data) {
      this.view = data;
    },
    openAddingModal(type, action, uid) {
      this.openAddKeywordModal.action = action;
      if (
        this.userItemsList === null ||
        (type == "seller" &&
          this.userSellers.length < this.plan.sellers_count) ||
        (type == "keyword" &&
          this.userKeywords.length < this.plan.keywords_count) ||
        action == "edit"
      ) {
        if (type == "seller") {
          this.$refs.addSellerModal.show = true;
        }
        if (type == "keyword") {
          // Clone keyword
          if (action == "clone") {
            let target = this.fetchedKeywords.find((el) => {
              return el.uid == uid;
            });
            this.newKeyword = Object.assign({}, target);
            this.newKeyword["categories"] = parseFloat(target.categories);
          }
          //or edit
          if (action == "edit") {
            for (var x = 0; x < this.fetchedKeywords.length; x++) {
              if (this.fetchedKeywords[x].uid == uid) {
                this.newKeyword = this.fetchedKeywords[x];
              }
            }
          }
          this.openAddKeywordModal.status = true;
          this.$nextTick(() => {
            this.$refs.keywordInput.focus();
          });
        } else {
          return false;
        }
      } else {
        this.showReachedLimit = true;
      }
    },
    closeAddingModal: function () {
      this.openAddSellerModal = false;
      this.openAddKeywordModal.status = false;
      this.newSeller.name = "";
      this.updateLoading = false;
      this.addSellerError = null;
      Object.assign(this.newKeyword, this.resetNewKeyword);
    },
    async saveSeller(newSeller) {
      this.updateLoading = true;

      var now = new Date();

      //Generate UID for new item
      var ID = function () {
        return "_" + Math.random().toString(36).substr(2, 9);
      };
      var uid = ID();
      let updatedSellers = [];
      if (this.fetchedSellers !== null) {
        updatedSellers = this.fetchedSellers;
      }
      let newSellerData = {
        name: newSeller.name,
        status: 1,
        type: "seller",
        rate: this.plan.sellers_rate,
        added: now,
        uid: uid,
        note: "",
        categories: newSeller.categories,
        buyButton: newSeller.buyButton,
        extraImages: newSeller.extraImages,
        allImages: newSeller.allImages,
        includeQuickBuyLink: newSeller.includeQuickBuyLink,
      };
      if (newSeller.look_for_modified == true) {
        newSellerData.look_for_modified = true;
      }
      updatedSellers.unshift(newSellerData);
      this.updateUserSellers(updatedSellers);
    },
    closeLimitModal() {
      this.showReachedLimit = false;
    },

    addKeyword: async function (action) {
      this.updateLoading = true;
      this.addSellerError = null;

      var now = new Date();

      var updatedKeywords;
      if (action == "edit") {
        updatedKeywords = this.fetchedKeywords;
        this.updateUserKeywords(updatedKeywords);
      } else {
        //Generate UID for new item
        var ID = function () {
          return "_" + Math.random().toString(36).substr(2, 9);
        };
        var uid = ID();

        if (this.fetchedKeywords !== null) {
          updatedKeywords = this.fetchedKeywords;
        } else {
          this.fetchedKeywords = [];
          this.fetchedKeywords.unshift({
            name: this.newKeyword.name,
            status: 1,
            type: "keyword",
            rate: this.newKeyword.rate,
            added: now,
            note: "",
            isFast: this.newKeyword.isFast,
            buyButton: this.newKeyword.buyButton,
            stopwords: this.newKeyword.stopwords,
            categories: this.newKeyword.categories,
            searchTitle: this.newKeyword.searchTitle,
            condition: this.newKeyword.condition,
            buyingFormat: this.newKeyword.buyingFormat,
            acceptsOffers: this.newKeyword.acceptsOffers,
            extraImages: this.newKeyword.extraImages,
            allImages: this.newKeyword.allImages,
            includeQuickBuyLink: this.newKeyword.includeQuickBuyLink,
            priceMin: this.newKeyword.priceMin,
            priceMax: this.newKeyword.priceMax,
            uid: uid,
          });
          updatedKeywords = [];
        }
        updatedKeywords.unshift({
          name: this.newKeyword.name,
          status: 1,
          type: "keyword",
          rate: this.newKeyword.rate,
          isFast: this.newKeyword.isFast,
          added: now,
          note: "",
          buyButton: this.newKeyword.buyButton,
          stopwords: this.newKeyword.stopwords,
          categories: this.newKeyword.categories,
          searchTitle: this.newKeyword.searchTitle,
          condition: this.newKeyword.condition,
          buyingFormat: this.newKeyword.buyingFormat,
          acceptsOffers: this.newKeyword.acceptsOffers,
          extraImages: this.newKeyword.extraImages,
          allImages: this.newKeyword.allImages,
          includeQuickBuyLink: this.newKeyword.includeQuickBuyLink,
          priceMin: this.newKeyword.priceMin,
          priceMax: this.newKeyword.priceMax,
          uid: uid,
        });
        this.updateUserKeywords(updatedKeywords);
      }
    },
  },
  mounted() {
    this.processCategories();
  },
  beforeMount() {
    this.getItems();
  },
};
</script>
<style lang="scss">
@import "@/scss/_tree-select.scss";
@import "@/scss/_sellers-list.scss";
</style>
