var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar"},[(_vm.user.profile)?_c('div',{staticClass:"user_info"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("sidebar.account_info")))]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t("sidebar.user_id"))+":")]),_c('div',{staticClass:"value"},[_c('span',{attrs:{"id":"userid"}},[_vm._v(_vm._s(_vm.user.profile.user_id))]),_c('span',{directives:[{name:"clipboard",rawName:"v-clipboard",value:(_vm.user.profile.user_id),expression:"user.profile.user_id"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"},{name:"tooltip",rawName:"v-tooltip",value:({
						content: _vm.tip.copyId[_vm.$i18n.locale],
						show: _vm.showTooltip,
						trigger: 'hover',
						hideOnTargetClick: false,
						placement: 'top-end',
					}),expression:"{\n\t\t\t\t\t\tcontent: tip.copyId[$i18n.locale],\n\t\t\t\t\t\tshow: showTooltip,\n\t\t\t\t\t\ttrigger: 'hover',\n\t\t\t\t\t\thideOnTargetClick: false,\n\t\t\t\t\t\tplacement: 'top-end',\n\t\t\t\t\t}"}],staticClass:"copy",on:{"mouseleave":_vm.originalTip}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'copy']}})],1)])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t("sidebar.current_plan"))+":")]),_c('div',{staticClass:"value"},[_c('span',{staticClass:"plan",style:({ 'background-color': _vm.plan.color })},[_vm._v(_vm._s(_vm.plan.name))])])]),_c('div',{staticClass:"item",staticStyle:{"margin-top":"30px"}},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.$t("sidebar.update_rate"))+" "),_c('strong',[_vm._v("["+_vm._s(_vm.$t("sidebar.sellers"))+"]")]),_vm._v(": ")]),_c('div',{staticClass:"value",style:({ color: _vm.plan.color })},[_vm._v(" "+_vm._s(_vm.plan.sellers_rate)+" "+_vm._s(_vm.$t("global.sec"))+" ")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.$t("sidebar.used"))+" "+_vm._s(_vm.$t("sidebar.sellers"))+": ")]),_c('div',{staticClass:"value"},[(_vm.userSellers)?_c('span',[_vm._v(_vm._s(_vm.userSellers.length))]):_c('span',[_vm._v("0")]),_vm._v(" of "+_vm._s(_vm.plan.sellers_count)+" ")])]),_c('div',{staticClass:"plan-range sellers"},[(_vm.userSellers)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
					content: _vm.userSellers.length + ' sellers',
					placement: 'bottom',
				}),expression:"{\n\t\t\t\t\tcontent: userSellers.length + ' sellers',\n\t\t\t\t\tplacement: 'bottom',\n\t\t\t\t}"}],staticClass:"used sellers",style:({ 'min-width': _vm.sellersPercent + '%' })}):_vm._e()]),_c('div',{staticClass:"item",staticStyle:{"margin-top":"30px"}},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.$t("sidebar.update_rate"))+" "),_c('strong',[_vm._v("["+_vm._s(_vm.$t("sidebar.keywords"))+"]")]),_vm._v(": ")]),_c('div',{staticClass:"value",style:({ color: _vm.plan.color })},[_vm._v(" "+_vm._s(_vm.plan.keywords_rate)+" "+_vm._s(_vm.$t("global.sec"))+" ")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.$t("sidebar.used"))+" "+_vm._s(_vm.$t("sidebar.keywords"))+": ")]),_c('div',{staticClass:"value"},[(_vm.userKeywords)?_c('span',[_vm._v(_vm._s(_vm.userKeywords.length))]):_c('span',[_vm._v("0")]),_vm._v(" of "+_vm._s(_vm.plan.keywords_count)+" ")])]),_c('div',{staticClass:"plan-range sellers"},[(_vm.userKeywords)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
					content: _vm.userKeywords.length + ' keywords',
					placement: 'bottom',
				}),expression:"{\n\t\t\t\t\tcontent: userKeywords.length + ' keywords',\n\t\t\t\t\tplacement: 'bottom',\n\t\t\t\t}"}],staticClass:"used keywords",style:({ 'min-width': _vm.keywordsPercent + '%' })}):_vm._e()])]):[_c('content-loader',{attrs:{"height":230,"width":400,"speed":2,"primaryColor":"#d1d1d1","secondaryColor":"#b6b6b6"}},[_c('rect',{attrs:{"x":"0","y":"15","rx":"0","ry":"0","width":"150","height":"20"}}),_c('rect',{attrs:{"x":"0","y":"65","rx":"0","ry":"0","width":"80","height":"18"}}),_c('rect',{attrs:{"x":"300","y":"65","rx":"0","ry":"0","width":"180","height":"18"}}),_c('rect',{attrs:{"x":"0","y":"100","rx":"0","ry":"0","width":"100","height":"18"}}),_c('rect',{attrs:{"x":"340","y":"100","rx":"0","ry":"0","width":"60","height":"18"}}),_c('rect',{attrs:{"x":"0","y":"135","rx":"0","ry":"0","width":"130","height":"18"}}),_c('rect',{attrs:{"x":"320","y":"135","rx":"0","ry":"0","width":"80","height":"18"}}),_c('rect',{attrs:{"x":"0","y":"170","rx":"0","ry":"0","width":"180","height":"18"}}),_c('rect',{attrs:{"x":"280","y":"170","rx":"0","ry":"0","width":"120","height":"18"}}),_c('rect',{attrs:{"x":"0","y":"205","rx":"0","ry":"0","width":"400","height":"18"}})])],_c('div',{staticClass:"tip"},[_c('div',{staticClass:"title"},[_vm._t("title",function(){return [_vm._v("title")]})],2),_c('div',{staticClass:"text"},[_vm._t("text",function(){return [_vm._v("text")]})],2)]),(_vm.$router.currentRoute.name !== 'notifications')?_c('div',{staticClass:"notifications-status"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("sidebar.notifications_channels")))]),(_vm.user.profile)?_c('div',{staticClass:"list"},[_c('div',{staticClass:"item"},[_vm._m(0),_c('span',[_vm._v("Telegram")]),_c('div',{staticClass:"status",class:{
						active:
							_vm.user.profile && _vm.user.profile.telegram == 'true',
					}},[(
							_vm.user.profile && _vm.user.profile.telegram == 'true'
						)?_c('span',[_vm._v(_vm._s(_vm.$t("sidebar.active")))]):_c('span',[_vm._v(_vm._s(_vm.$t("sidebar.disabled")))])])])]):_vm._e(),_c('div',{staticClass:"link"},[_c('router-link',{attrs:{"to":"/notifications"}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'cog']}}),_vm._v(" "+_vm._s(_vm.$t("sidebar.manage_notifications")))],1)],1)]):_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"thumb"},[_c('img',{attrs:{"src":require("@/assets/telegram.svg"),"alt":""}})])
}]

export { render, staticRenderFns }