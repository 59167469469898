<template>
  <div class="no-items">
    <div
      class="text-center"
      style="max-width: 100%; width: 500px; margin: 0px auto"
    >
      <div class="image" style="margin-bottom: 10px">
        <img src="@/assets/no_messenger.svg" height="160" alt />
      </div>
      <h2>
        <strong>{{ $t("list.welcome") }}!</strong>
      </h2>
      {{ $t("list.no_messengers") }}
      <br />
      <br />
      {{ $t("list.added_refresh") }}
      <div>
        <router-link
          to="/notifications"
          class="button small"
          style="margin-top: 30px"
        >
          <font-awesome-icon
            :icon="['fa', 'envelope']"
            style="margin-right: 10px"
          />
          {{ $t("menu.notifications") }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
