<template>
  <div class="modal" v-if="show == true">
    <div class="modal__wrapper">
      <div class="card" style="border: 2px solid #20b15c; background: #e3ffee">
        <div class="modal__title">🙀 {{ $t("adding.oops") }}!</div>
        <div class="modal__text">
          {{ $t("adding.reached_limit") }}
        </div>
        <div class="modal__controls upgrade-modal">
          <router-link
            :to="{ name: 'pricing' }"
            class="button button_primary upgrade"
          >
            <font-awesome-icon :icon="['fas', 'rocket']" mask />
            {{ $t("adding.upgrade") }}
          </router-link>
          <button-primary
            :onClick="
              () => {
                closeSelf();
              }
            "
            :class="'small secondary black'"
            >{{ $t("adding.cancel") }}</button-primary
          >
        </div>
      </div>
    </div>
  </div>
  <!-- /reachedLimitModal -->
</template>

<script>
export default {
  props: ["show"],
  data() {
    return {};
  },
  methods: {
    closeSelf() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped></style>
