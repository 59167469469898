<template>
  <div class="modal" v-if="show == true">
    <div class="modal__wrapper">
      <div class="card">
        <div class="modal__title">{{ $t("adding.remove") }} ?</div>
        <div class="modal__text">
          <strong>{{ itemToRemove.name }}</strong>
          {{ $t("adding.removed") }}
        </div>
        <div class="modal__controls">
          <button-primary
            :onClick="
              () => {
                cancel();
              }
            "
            :class="'small secondary black'"
            >{{ $t("adding.cancel") }}</button-primary
          >
          <button-primary
            :onClick="
              () => {
                confirm();
              }
            "
            :class="'small delete'"
          >
            <span v-if="!updateLoading">{{ $t("adding.remove") }}</span>
            <font-awesome-icon
              v-if="updateLoading == true"
              :icon="['fas', 'circle-notch']"
              spin
            />
          </button-primary>
        </div>
      </div>
    </div>
  </div>
  <!-- /removingModal -->
</template>

<script>
export default {
  props: ["show", "itemToRemove", "updateLoading"],
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>

<style lang="scss" scoped></style>
