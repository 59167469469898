<template>
  <button
    @click="onClick()"
    class="button"
    :id="id"
    :class="{ loading: state == 'loading' }"
  >
    <slot>Button</slot>
  </button>
</template>
<script>
export default {
  name: "buttonPrimary",
  props: {
    text: {
      type: [String],
      default: "Button text",
    },
    onClick: {
      type: Function,
      required: false,
    },
    id: {
      type: [String],
      required: false,
    },
    state: {
      type: [String],
      required: false,
    },
  },
};
</script>
<style lang="scss">
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  height: 56px;
  border: none;
  border-radius: 4px;
  background-color: #00a3ff;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  -webkit-appearance: none !important;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: 0px 2px 4px 0px rgba(#000, 0.2);
  outline: none !important;
  position: relative;
  text-decoration: none;
  box-sizing: border-box;
  &:hover {
    background: lighten(#00a3ff, 3%);
    box-shadow: 0px 2px 8px 0px rgba(#000, 0.3);
    transition: 0.3s;
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
    &:hover {
      background-color: #00a3ff;
      box-shadow: 0px 2px 4px 0px rgba(#000, 0.2);
    }
  }
  &.delete {
    background-color: #bd3939;
  }
  &.small {
    height: 36px;
  }
  &.complete-registration,
  &.upgrade {
    background: #20b15c;
    transition: 0.3s;
    svg {
      margin-right: 5px;
    }
    &:hover {
      background: lighten(#20b15c, 5%);
      transition: 0.3s;
    }
  }
  &.secondary {
    background: none;
    box-shadow: none;
    color: #00a3ff;
    &.black {
      color: #000;
    }
    &:hover {
      background: #f1f1f1;
    }
  }
  &.google {
    background: #fff;
    color: #000;
    border: 1px solid #e7e7e7;
    .google-bg {
      margin-right: 10px;
      height: 28px;
      width: 28px;
      background: #fff;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
    }
    &:hover {
      background: lighten(#e7e7e7, 5%);
    }
  }
  &_login {
    width: 200px;
  }
}
</style>
