<template>
	<div class="sidebar">
		<div class="user_info" v-if="user.profile">
			<div class="title">{{ $t("sidebar.account_info") }}</div>
			<div class="item">
				<div class="name">{{ $t("sidebar.user_id") }}:</div>
				<div class="value">
					<span id="userid">{{ user.profile.user_id }}</span>
					<span
						v-clipboard="user.profile.user_id"
						class="copy"
						v-clipboard:success="onCopy"
						v-on:mouseleave="originalTip"
						v-tooltip="{
							content: tip.copyId[$i18n.locale],
							show: showTooltip,
							trigger: 'hover',
							hideOnTargetClick: false,
							placement: 'top-end',
						}"
					>
						<font-awesome-icon :icon="['far', 'copy']"
					/></span>
				</div>
			</div>
			<div class="item">
				<div class="name">{{ $t("sidebar.current_plan") }}:</div>
				<div class="value">
					<span
						class="plan"
						v-bind:style="{ 'background-color': plan.color }"
						>{{ plan.name }}</span
					>
				</div>
			</div>
			<div class="item" style="margin-top: 30px;">
				<div class="name">
					{{ $t("sidebar.update_rate") }}
					<strong>[{{ $t("sidebar.sellers") }}]</strong>:
				</div>
				<div class="value" v-bind:style="{ color: plan.color }">
					{{ plan.sellers_rate }} {{ $t("global.sec") }}
				</div>
			</div>
			<div class="item">
				<div class="name">
					{{ $t("sidebar.used") }} {{ $t("sidebar.sellers") }}:
				</div>
				<div class="value">
					<span v-if="userSellers">{{ userSellers.length }}</span>
					<span v-else>0</span> of {{ plan.sellers_count }}
				</div>
			</div>
			<div class="plan-range sellers">
				<div
					class="used sellers"
					v-if="userSellers"
					v-tooltip="{
						content: userSellers.length + ' sellers',
						placement: 'bottom',
					}"
					v-bind:style="{ 'min-width': sellersPercent + '%' }"
				></div>
			</div>
			<div class="item" style="margin-top: 30px;">
				<div class="name">
					{{ $t("sidebar.update_rate") }}
					<strong>[{{ $t("sidebar.keywords") }}]</strong>:
				</div>
				<div class="value" v-bind:style="{ color: plan.color }">
					{{ plan.keywords_rate }} {{ $t("global.sec") }}
				</div>
			</div>
			<div class="item">
				<div class="name">
					{{ $t("sidebar.used") }} {{ $t("sidebar.keywords") }}:
				</div>
				<div class="value">
					<span v-if="userKeywords">{{ userKeywords.length }}</span>
					<span v-else>0</span> of {{ plan.keywords_count }}
				</div>
			</div>
			<div class="plan-range sellers">
				<div
					class="used keywords"
					v-if="userKeywords"
					v-tooltip="{
						content: userKeywords.length + ' keywords',
						placement: 'bottom',
					}"
					v-bind:style="{ 'min-width': keywordsPercent + '%' }"
				></div>
			</div>
		</div>
		<template v-else>
			<content-loader
				:height="230"
				:width="400"
				:speed="2"
				primaryColor="#d1d1d1"
				secondaryColor="#b6b6b6"
			>
				<rect x="0" y="15" rx="0" ry="0" width="150" height="20" />
				<rect x="0" y="65" rx="0" ry="0" width="80" height="18" />
				<rect x="300" y="65" rx="0" ry="0" width="180" height="18" />
				<rect x="0" y="100" rx="0" ry="0" width="100" height="18" />
				<rect x="340" y="100" rx="0" ry="0" width="60" height="18" />
				<rect x="0" y="135" rx="0" ry="0" width="130" height="18" />
				<rect x="320" y="135" rx="0" ry="0" width="80" height="18" />
				<rect x="0" y="170" rx="0" ry="0" width="180" height="18" />
				<rect x="280" y="170" rx="0" ry="0" width="120" height="18" />
				<rect x="0" y="205" rx="0" ry="0" width="400" height="18" />
			</content-loader>
		</template>
		<div class="tip">
			<div class="title">
				<slot name="title">title</slot>
			</div>
			<div class="text">
				<slot name="text">text</slot>
			</div>
		</div>
		<div
			class="notifications-status"
			v-if="$router.currentRoute.name !== 'notifications'"
		>
			<div class="title">{{ $t("sidebar.notifications_channels") }}</div>
			<div class="list" v-if="user.profile">
				<div class="item">
					<div class="thumb">
						<img src="@/assets/telegram.svg" alt="" />
					</div>
					<span>Telegram</span>
					<div
						class="status"
						:class="{
							active:
								user.profile && user.profile.telegram == 'true',
						}"
					>
						<span
							v-if="
								user.profile && user.profile.telegram == 'true'
							"
							>{{ $t("sidebar.active") }}</span
						>
						<span v-else>{{ $t("sidebar.disabled") }}</span>
					</div>
				</div>
				<!-- /item -->
			</div>
			<div class="link">
				<router-link to="/notifications">
					<font-awesome-icon :icon="['fas', 'cog']" />
					{{ $t("sidebar.manage_notifications") }}</router-link
				>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import { ContentLoader } from "vue-content-loader";
export default {
	name: "Sidebar",
	data() {
		return {
			tip: {
				copyId: {
					en: "Copy Your user ID to clipboard",
					ru: "Копировать ID в буфер",
				},
				copyIdMatrix: {
					en: "Copy Your user ID to clipboard",
					ru: "Копировать ID в буфер",
				},
				copyIdCopied: {
					en: "Copied!",
					ru: "Скопировано!",
				},
			},
			showTooltip: false,
		};
	},
	computed: {
		// map `this.user` to `this.$store.getters.user`
		...mapGetters({
			user: "user",
			plans: "plans",
			userSellers: "userSellers",
			userKeywords: "userKeywords",
		}),
		totalUserItems: function() {
			var sellsers = 0;
			var keywords = 0;
			if (this.userSellers !== null) {
				sellsers = this.userSellers.length;
			}
			if (this.userKeywords !== null) {
				keywords = this.userKeywords.length;
			}
			return sellsers + keywords;
		},
		sellersPercent: function() {
			var sellers = this.userSellers.length;
			var itemLength = 100 / this.plan.sellers_count;
			return sellers * itemLength;
		},
		keywordsPercent: function() {
			var keywords = this.userKeywords.length;
			var itemLength = 100 / this.plan.keywords_count;
			return keywords * itemLength;
		},
		plan: function() {
			if (this.user.profile) {
				var planId = this.user.profile.plan;
				var plan;
				for (var i = 0, len = this.plans.length; i < len; i++) {
					if (this.plans[i].id == planId) {
						plan = this.plans[i];
					}
				}
				return plan;
			} else {
				return 0;
			}
		},
	},
	props: {
		text: String,
		title: String,
	},
	components: {
		ContentLoader,
	},
	methods: {
		onCopy: function() {
			this.tip.copyId[this.$i18n.locale] = this.tip.copyIdCopied[
				this.$i18n.locale
			];
		},
		originalTip: function() {
			setTimeout(() => {
				this.tip.copyId[this.$i18n.locale] = this.tip.copyIdMatrix[
					this.$i18n.locale
				];
			}, 300);
		},
	},
};
</script>
<style lang="scss" scoped>
.user_info {
	padding-top: 5px;

	.plan-range {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		position: relative;
		height: 8px;
		background-color: #ccc;
		overflow: hidden;
		border-radius: 3px;

		.used {
			height: 8px;
			transition: 0.3s;

			&.sellers {
				background-color: #ffb100;

				&:hover {
					background-color: lighten(#ffb100, 8%);
				}
			}

			&.keywords {
				background-color: #9100ff;

				&:hover {
					background-color: lighten(#9100ff, 8%);
				}
			}
		}
	}

	.item {
		display: flex;
		justify-content: space-between;
		margin-bottom: 15px;

		.name {
			color: #666;
			font-size: 14px;
		}

		.value {
			font-weight: 500;
			font-size: 14px;

			.plan {
				border-radius: 4px;
				padding: 3px 8px;
				color: #fff;
				font-weight: 500;
				text-transform: uppercase;
			}

			.copy {
				margin-left: 5px;
				color: #666;
				padding: 3px;
				cursor: pointer;
				opacity: 0.5;
				transition: 0.3s;

				&:hover {
					opacity: 1;
					transition: 0.3s;
				}
			}
		}
	}
}

.sidebar {
	margin-left: 30px;
	width: calc(25% - 30px);
	min-width: calc(25% - 30px);

	@media (max-width: 991px) {
		width: 100%;
		min-width: 100%;
		margin-top: 30px;
		margin-left: 0px;
	}

	.tip {
		border-bottom: 1px solid #e7e7e7;
		padding-top: 40px;

		.text {
			font-size: 16px;
			line-height: 24px;
			color: #989898;
			margin-bottom: 20px;

			a {
				color: #676666;
				font-weight: 500;
			}
		}

		.title {
			color: #757575 !important;
			font-size: 18px;
			margin-bottom: 15px;
			font-weight: bold;
		}
	}

	.title {
		color: #424242;
		font-size: 18px;
		margin-bottom: 15px;
		font-weight: bold;
	}
}

.notifications-status {
	margin-top: 30px;

	.link {
		text-align: right;
		padding-bottom: 10px;

		a {
			color: #2aa7f9;
			text-decoration: none;
			font-size: 14px;
		}
	}

	.title {
		margin-bottom: 30px;
	}

	.list {
		.item {
			display: flex;
			align-items: center;
			margin-bottom: 10px;

			&:last-child {
				padding-bottom: 20px;
				border-bottom: 1px solid #e7e7e7;
			}

			span {
				font-weight: normal;
				font-size: 14px;
				color: #606060;
			}

			.thumb {
				margin-right: 15px;
				min-width: 32px;
			}

			.status {
				margin-left: auto;
				border-radius: 6px;
				width: 80px;
				height: 24px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				text-transform: uppercase;
				background: #d7d7d7;
				color: #938f8f;
				font-size: 14px;

				&.active {
					color: #0e7418;
					background: #cdfdcc;
				}

				&.error {
					background: #fdcccc;
					color: #d44949;
				}
			}
		}
	}
}
</style>
