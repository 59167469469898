<template>
  <div class="no-items">
    <div class="text-center">
      {{ $t("list.no_items") }}
      <br />
      {{ $t("list.start_adding") }}
      <div class="image">
        <img src="@/assets/add_items.svg" height="96" alt />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
