import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'


const vuexPersist = new VuexPersist({
  key: 'UnderMRKT',
  storage: window.localStorage
})


Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        user: {
            loggedIn: false,
            data: null,
            profile: null

        },
        plans: {

        },
        userSellers: {

        },
        userKeywords: {

        }
    },
    getters: {
        user(state) {
            return state.user
        },
        userSellers(state) {
            return state.userSellers
        },
        userKeywords(state) {
            return state.userKeywords
        },
        plans(state) {
            return state.plans
        },
        profile(state) {
            return state.user.profile
        }
    },
    mutations: {
        SET_LOGGED_IN(state, value) {
            state.user.loggedIn = value;
        },
        SET_USER(state, data) {
            state.user.data = data;
        },
        SET_USERSELLERS(state, data) {
            state.userSellers = data;
        },
        SET_USERKEYWORDS(state, data) {
            state.userKeywords = data;
        },
        SET_PLANS(state, data) {
            state.plans = data;
        },
        SET_PROFILE(state, data) {
            state.user.profile = data;
        }
    },
    actions: {
        fetchUser({ commit }, user) {
            //commit("SET_LOGGED_IN", user !== null);
            if (user) {
                commit("SET_USER", {
                    displayName: user.displayName,
                    email: user.email,
                    uid: user.uid
                });
            } else {
                commit("SET_USER", null);
            }
        },
        fetchPlans({ commit }, plans) {
            commit("SET_PLANS", plans);
        },
        fetchProfile({ commit }, profile) {
            commit("SET_PROFILE", profile);
        },
        fetchUserSellers({ commit }, sellers) {
            commit("SET_USERSELLERS", sellers);
        },
        fetchUserKeywords({ commit }, keywords) {
            commit("SET_USERKEYWORDS", keywords);
        },
    },
    modules: {},
    plugins: [vuexPersist.plugin]
})