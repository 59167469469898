<template>
  <div class="modal" v-if="show == true">
    <div class="modal__wrapper">
      <div class="card">
        <div class="modal__title">+ {{ $t("list.btn_add_seller") }}</div>
        <div class="form-group">
          <label>{{ $t("adding.seller_id") }}</label>
          <input
            type="text"
            ref="sellerInput"
            pattern="[A-Za-z]"
            v-model="newSeller.name"
            v-on:keyup.enter="checkIfSellerExists"
          />
          <div v-if="addSellerError" class="alert alert-danger">
            {{ addSellerError }}
          </div>
        </div>
        <div class="form-group" v-if="user.profile.plan != 0">
          <label
            >{{ $t("adding.seller_category_title") }} ({{
              $t("adding.optional")
            }})</label
          >
          <treeselect
            v-model="newSeller.categories"
            :multiple="false"
            :options="categories"
            :searchable="false"
            :noChildrenText="'No child categories'"
          />
        </div>
        <div class="form-group">
          <label>{{ $t("adding.checkout_link") }}</label>
          <div class="ui-checkbox">
            <label>
              <input type="checkbox" v-model="newSeller.includeQuickBuyLink" />
              <span>
                <i></i>
              </span>
            </label>
          </div>
        </div>
        <div class="form-group">
          <label>{{ $t("adding.check_for_modified") }}</label>
          <div class="ui-checkbox">
            <label>
              <input type="checkbox" v-model="newSeller.look_for_modified" />
              <span>
                <i></i>
              </span>
            </label>
          </div>
        </div>
        <div class="form-group" v-if="plan && plan.buyButton == true">
          <label>Buy button</label>
          <div class="ui-checkbox">
            <label>
              <input type="checkbox" v-model="newSeller.buyButton" />
              <span>
                <i></i>
              </span>
            </label>
          </div>
        </div>
        <div class="form-group">
          <label>{{ $t("adding.all_images") }}</label>
          <div class="ui-checkbox">
            <label>
              <input type="checkbox" v-model="newSeller.allImages" />
              <span>
                <i></i>
              </span>
            </label>
          </div>
        </div>
        <div class="form-group" v-show="!newSeller.allImages">
          <label>{{ $t("adding.extra_images") }} </label>
          <div class="ui-radio-group">
            <label v-for="(n, i) in 4" :key="i">
              <input
                type="radio"
                :value="i"
                checked
                v-model="newSeller.extraImages"
              />
              <span>{{ i }}</span>
            </label>
          </div>
        </div>
        <div class="modal__controls">
          <button-primary
            :onClick="() => (show = false)"
            :class="'small secondary'"
            >{{ $t("adding.cancel") }}</button-primary
          >
          <button-primary
            :disabled="newSeller.name.length < 3"
            :onClick="checkIfSellerExists"
            :class="'small'"
          >
            <span v-if="!updateLoading && !isLoading">{{
              $t("adding.save")
            }}</span>
            <font-awesome-icon
              v-if="updateLoading || isLoading"
              :icon="['fas', 'circle-notch']"
              spin
            />
          </button-primary>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth"; // for authentication
import "firebase/storage"; // for storage
import "firebase/firestore"; // for cloud firestore
import "firebase/functions"; // for cloud functions
import { mapGetters } from "vuex";
import Treeselect from "@riophae/vue-treeselect";

export default {
  props: ["categories", "updateLoading"],
  components: {
    Treeselect,
  },
  data() {
    return {
      show: false,
      addSellerError: null,
      isLoading: false,
      newSeller: {
        name: "",
        rate: "",
        look_for_modified: false,
        categories: null,
        extraImages: 0,
        allImages: false,
        buyButton: false,
        includeQuickBuyLink: true,
      },
      defaults: {
        name: "",
        rate: "",
        look_for_modified: false,
        categories: null,
        extraImages: 0,
        allImages: false,
        buyButton: false,
        includeQuickBuyLink: true,
      },
    };
  },
  watch: {
    updateLoading: {
      handler() {
        this.isLoading = this.updateLoading;
      },
    },
    show: {
      handler(newVal) {
        if (newVal) {
          this.newSeller = { ...this.defaults };
          this.$nextTick(() => {
            this.$refs.sellerInput.focus();
          });
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
      plans: "plans",
      userSellers: "userSellers",
      userKeywords: "userKeywords",
    }),
    plan() {
      if (this.user.profile) {
        var planId = this.user.profile.plan;
        var plan;
        for (var i = 0, len = this.plans.length; i < len; i++) {
          if (this.plans[i].id == planId) {
            plan = this.plans[i];
          }
        }
        return plan;
      } else {
        return 0;
      }
    },
  },
  methods: {
    checkIfSellerExists() {
      this.isLoading = true;
      if (this.newSeller.name.length < 2) {
        this.addSellerError = "Too short seller ID";
      } else {
        this.isLoading = false;
        this.$emit("saveSeller", this.newSeller);
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
