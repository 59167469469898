import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import firebase from 'firebase/app'
import 'firebase/auth'; // for authentication
import 'firebase/storage'; // for storage
import 'firebase/firestore'; // for cloud firestore
import 'firebase/functions'; // for cloud functions

Vue.use(VueRouter)

const routes = [{
	path: '/monitoring',
	name: 'home',
	component: Home,
	meta: {
		requiresAuth: true,
		title: 'Home Page - Example App',
		metaTags: [{
			name: 'description',
			content: 'The home page of our example app.'
		},
		{
			property: 'og:description',
			content: 'The home page of our example app.'
		}
		]
	}
},
{
	path: '/login',
	name: 'login',

	component: () => import( /* webpackChunkName: "login" */ '../views/Login.vue')
},

{
	path: '/register',
	name: 'register',

	component: () => import( /* webpackChunkName: "register" */ '../views/Register.vue')
},

{
	path: '/forgot',
	name: 'forgot',

	component: () => import( /* webpackChunkName: "register" */ '../views/ForgotPassword.vue')
},
{
	path: '/account',
	name: 'account',
	meta: {
		requiresAuth: true
	},

	component: () => import('../views/Account.vue')
},
{
	path: '/notifications',
	name: 'notifications',
	meta: {
		requiresAuth: true
	},

	component: () => import('../views/Notifications.vue')
},
{
	path: '/history',
	name: 'history',
	meta: {
		requiresAuth: true
	},

	component: () => import('../views/History.vue')
},
{
	path: '/pricing',
	name: 'pricing',
	meta: {
		requiresAuth: true
	},

	component: () => import( /* webpackChunkName: "pricing" */ '../views/Pricing.vue')
},
{
	path: '/*',
	redirect: '/monitoring',
},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
	firebase.auth().onAuthStateChanged((user) => {
		if (requiresAuth && !user) next('login');
		// else if (user && !user.email && to.path == "/login") next('/register');
		// else if (user && user.email && to.path == "/login") next('/monitoring');
		// else if (user && user.email && to.path == "/register") next('/monitoring');
		else next();
	});
});

// router.beforeEach((to, from, next) => {
//     let requiresAuth = to.matched.some(record => record.meta.requiresAuth);
//     firebase.auth().onAuthStateChanged((user) => {
//         if (requiresAuth && !user) {
//             next('/login')
//         } else {
//             next()
//         }
//     });
// })

export default router