import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueTelInput from "vue-tel-input";
import vueHeadful from "vue-headful";
import VTooltip from "v-tooltip";
import vSelect from "vue-select";
import Clipboard from "v-clipboard";

import VueI18n from "vue-i18n";
import i18n from "@/translations/index.js";

import LiqPay from "./modules/liqpay.js";
//import Treeselect from '@riophae/vue-treeselect'

import { library } from "@fortawesome/fontawesome-svg-core";
import { faBinoculars } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { faRocket } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faStickyNote } from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { faClone } from "@fortawesome/free-regular-svg-icons";
import { faStopwatch } from "@fortawesome/free-solid-svg-icons";
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { faUnlock } from "@fortawesome/free-solid-svg-icons";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import _ from "lodash";

import firebase from "firebase/app";
import "firebase/auth"; // for authentication
import "firebase/storage"; // for storag
import "firebase/firestore"; // for cloud firestore
import "firebase/functions"; // for cloud functions
import "./registerServiceWorker";

library.add(
  faBinoculars,
  faHistory,
  faEnvelope,
  faQuestionCircle,
  faDollarSign,
  faCaretDown,
  faLock,
  faMobileAlt,
  faTrash,
  faExternalLinkAlt,
  faCircleNotch,
  faSignOutAlt,
  faCog,
  faUser,
  faCopy,
  faRocket,
  faCheck,
  faStickyNote,
  faInfoCircle,
  faClone,
  faPencilAlt,
  faStopwatch,
  faStar,
  faTimes,
  faUnlock,
  faUserPlus,
  faArrowLeft,
  faTag
);

//Buttons
import ButtonPrimary from "@/components/buttons/ButtonPrimary.vue";
import ContextMenu from "@/components/buttons/ContextMenu.vue";

//Features
import sellersList from "@/components/features/SellersList.vue";

Vue.component("v-select", vSelect);
//Vue.component('treeselect',Treeselect)

Vue.use(VueTelInput);

//moment
const moment = require("moment");
require("moment/locale/ru");
Vue.use(require("vue-moment"), {
  moment,
});

//Tooltip
Vue.use(VTooltip);

//Copy 2 clipboard
Vue.use(Clipboard);

//icons
Vue.component("font-awesome-icon", FontAwesomeIcon);

//Buttons
Vue.component("ButtonPrimary", ButtonPrimary);
Vue.component("ContextMenu", ContextMenu);

//Features
Vue.component("sellersList", sellersList);

//Service stuff
Vue.component("vue-headful", vueHeadful);

Vue.config.productionTip = false;

const firebaseConfig = {
  apiKey: "AIzaSyCf4YejEB_5SA-1X5adfjzDDmmyKIJ9zg8",
  authDomain: "undermarkt-ae574.firebaseapp.com",
  databaseURL: "https://undermarkt-ae574.firebaseio.com",
  projectId: "undermarkt-ae574",
  storageBucket: "undermarkt-ae574.appspot.com",
  messagingSenderId: "219664821385",
  appId: "1:219664821385:web:61aa540c103321ea1a6a58",
  measurementId: "G-96QTVQ8VN2",
};
firebase.initializeApp(firebaseConfig);
// firebase.functions().useFunctionsEmulator("http://localhost:5000");

firebase.auth().onAuthStateChanged(async (user) => {
  if (user) {
    const usersRef = db.collection("users").doc(user.uid);
    await usersRef
      .get()
      .then((doc) => {
        /* eslint-disable no-console */
        if (doc.data() && doc.data().user_id) {
          store.dispatch("fetchProfile", doc.data());
        } else {
          // firebase
          //     .auth()
          //     .signOut()
          //     .then(() => {
          //         this.$router.replace({
          //             name: "login",
          //         });
          //     });
        }
      })
      .catch((error) => {
        /* eslint-disable no-console */
        console.log("error:" + error);
      });
    store.dispatch("fetchUser", user);
  }
});

export const db = firebase.firestore();

//Get all plans and save to store
db.collection("plans")
  .get()
  .then((querySnapshot) => {
    var plans = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      plans.push(doc.data());
    });
    store.dispatch("fetchPlans", plans);
  });

new Vue({
  i18n,
  router,
  store,
  firebase,
  vueHeadful,
  VueTelInput,
  LiqPay,
  render: (h) => h(App),
}).$mount("#app");
