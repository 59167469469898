<template>
  <div id="app">
    <header
      class="header"
      v-bind:class="{
        'justify-content-center':
          this.$route.path == '/login' ||
          this.$route.path == '/register' ||
          this.$route.path == '/forgot',
      }"
    >
      <div class="header__logo">
        <template
          v-if="
            user.loggedIn &&
              this.$route.path != '/login' &&
              this.$route.path != '/register'
          "
        >
          <div
            class="header__menu-toggle"
            @click="mobileMenuOpened = !mobileMenuOpened"
            v-bind:class="{
              'menu-opened': mobileMenuOpened == true,
            }"
          >
            <i></i>
            <i></i>
            <i></i>
          </div>
        </template>
        <router-link to="/" class="logo">
          <img src="@/assets/logo.svg" alt />
          <strong>UNDER</strong>MRKT
        </router-link>
      </div>
      <template
        v-if="
          user.loggedIn &&
            this.$route.path != '/login' &&
            this.$route.path != '/register'
        "
      >
        <div
          class="header__menu"
          v-bind:class="{ 'menu-opened': mobileMenuOpened == true }"
        >
          <router-link to="/monitoring">
            <font-awesome-icon :icon="['fas', 'binoculars']" />
            {{ $t("menu.monitoring") }}
          </router-link>
          <router-link to="/notifications">
            <font-awesome-icon :icon="['fa', 'envelope']" />
            {{ $t("menu.notifications") }}
          </router-link>
          <router-link to="/pricing">
            <font-awesome-icon :icon="['fa', 'dollar-sign']" />
            {{ $t("menu.pricing") }}
          </router-link>
        </div>
      </template>
      <template
        v-if="
          user.loggedIn &&
            this.$route.path != '/login' &&
            this.$route.path != '/register'
        "
      >
        <div class="header__controls">
          <div
            class="active-plan free"
            v-bind:style="{ background: plan.color }"
          >
            {{ plan.name }}
          </div>
          <div class="user-account" @click="showUserMenu = !showUserMenu">
            <div class="info">
              <span v-if="user && user.data">{{ user.data.email }}</span>
              <div class="identity">{{ login }}</div>
              <font-awesome-icon :icon="['fa', 'caret-down']" />
            </div>
            <div class="user-menu" v-show="showUserMenu">
              <font-awesome-icon :icon="['fa', 'caret-down']" />
              <div class="item">
                <router-link to="/account">
                  <font-awesome-icon :icon="['fas', 'user']" />
                  {{ $t("menu.account") }}
                </router-link>
              </div>
              <div class="item">
                <a
                  href="#"
                  @click.prevent="openPromocodeModal"
                  style="color:#ff4b2e;"
                >
                  <font-awesome-icon :icon="['fa', 'tag']" />
                  Use promo code
                </a>
              </div>
              <div class="item">
                <a href="#" @click="signOut()">
                  <font-awesome-icon :icon="['fa', 'sign-out-alt']" />
                  {{ $t("menu.logout") }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </template>
    </header>
    <router-view />
    <footer>
      <ul class="footer__links">
        <li>
          <a href="https://undermrkt.com/">{{ $t("menu.home") }}</a>
        </li>
        <li>
          <a href="https://undermrkt.com/privacy-policy/">{{
            $t("menu.privacy_policy")
          }}</a>
        </li>
        <li>
          <a href="https://undermrkt.com/faq">{{ $t("menu.faq") }}</a>
        </li>
        <li>
          <a href="https://undermrkt.com/#contact">{{
            $t("menu.contact_us")
          }}</a>
        </li>
        <li>
          <span>{{ $t("menu.language") }}:</span>
          <select v-model="$i18n.locale" @change="changeLang()">
            <option
              v-for="(lang, i) in langs"
              :key="`Lang${i}`"
              :value="lang"
              >{{ langLabels[lang] }}</option
            >
          </select>
        </li>
      </ul>
      <div class="footer__logos text-center">
        <img src="@/assets/compatibleapplication-vert.png" alt height="36" />
        <img src="@/assets/visa.png" alt height="36" />
        <img src="@/assets/mc.png" alt height="36" />
      </div>
    </footer>

    <div class="modal" v-if="showPromocodeModal == true">
      <div class="modal__wrapper">
        <div class="card">
          <div class="modal__title">🏷️ Use a promo code</div>
          <div
            class="form-group keyword-price"
            v-show="promocode_result.class !== 'success'"
          >
            <input
              :disabled="isLoading"
              type="text"
              v-model="promocode_value"
              placeholder="Enter code here"
            />
          </div>
          <div
            class="message"
            :class="promocode_result.class"
            v-show="!isLoading && promocode_result.text.length > 3"
          >
            {{ promocode_result.text }}
            <strong v-if="promocode_result.class == 'success'"
              >{{ prmocode_timer }}s.</strong
            >
          </div>
          <div
            class="modal__controls upgrade-modal"
            v-show="promocode_result.class !== 'success'"
          >
            <button-primary
              :disabled="isLoading && !promocode_value"
              :onClick="applyPromocode"
              :class="'primary'"
            >
              <font-awesome-icon
                v-if="isLoading"
                :icon="['fas', 'circle-notch']"
                spin
              />
              <span v-if="!isLoading">Use code</span>
            </button-primary>
            <button-primary
              :disabled="isLoading"
              :onClick="
                () => {
                  showPromocodeModal = false;
                }
              "
              :class="'small secondary black'"
              >Close</button-primary
            >
          </div>
        </div>
      </div>
    </div>
    <!-- /reachedLimitModal -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import firebase from "firebase/app";
import "firebase/auth"; // for authentication
import "firebase/firestore"; // for cloud firestore

import UAParser from "ua-parser-js";
export default {
  data() {
    return {
      lead_source: null,
      api_base: "https://us-central1-undermarkt-ae574.cloudfunctions.net/api",
      // api_base: "http://localhost:5001/undermarkt-ae574/us-central1/api",
      showPromocodeModal: false,
      showUserMenu: false,
      mobileMenuOpened: false,
      langs: ["en", "ru"],
      isLoading: false,
      promocode_value: "",
      prmocode_timer: 5,
      promocode_result: {
        text: "",
        class: "error",
      },
      langLabels: {
        ru: "Русский",
        en: "English",
      },
      geo_data: "",
      ua_info: "",
    };
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
      plans: "plans",
    }),
    plan: function() {
      var plan;
      var planId = 1;
      if (this.user.profile) {
        planId = this.user.profile.plan;
      }
      for (var i = 0, len = this.plans.length; i < len; i++) {
        if (this.plans[i].id == planId) {
          plan = this.plans[i];
        }
      }
      return plan;
    },
    login: function() {
      if (this.user.data && this.user.data.email) {
        return this.user.data.email.charAt(0);
      } else {
        return 0;
      }
    },
  },
  watch: {
    $route() {
      this.mobileMenuOpened = false;
    },
  },

  methods: {
    saveLeadSource: function() {
      if (this.$route.query.campaign) {
        localStorage.lead_source = this.$route.query.campaign;
      }
    },
    async geoLocate() {
      async function httpGetAsync(url, callback) {
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = function() {
          if (xmlHttp.readyState == 4 && xmlHttp.status == 200)
            callback(xmlHttp.responseText);
        };
        xmlHttp.open("GET", url, true); // true for asynchronous
        xmlHttp.send(null);
      }

      var url =
        "https://ipgeolocation.abstractapi.com/v1/?api_key=977786f851824d68ba98047686ac32a7";

      var result = {};
      await httpGetAsync(url, (response) => {
        this.geo_data = response;
        this.getUserInfo();
      });
    },
    getDeviceInfo: function() {
      var infoSections = {};
      var parser = new UAParser();
      var userOs = parser.getOS();
      var userDevice = parser.getDevice();
      var userBrowser = parser.getBrowser();

      if (userOs && userOs.name && userOs.version) {
        infoSections["os"] = userOs.name + " " + userOs.version;
      }

      if (userBrowser && userBrowser.name && userBrowser.version) {
        infoSections["browser"] = userBrowser.name + " " + userBrowser.version;
      }

      if (userDevice && userDevice.vendor && userDevice.model) {
        infoSections["device"] = userBrowser.vendor + " " + userBrowser.model;
      } else {
        infoSections["device"] = "N/A";
      }

      if (window) {
        if (window.screen) {
          infoSections["screen_resolution"] =
            window.screen.width + "x" + window.screen.height;
          infoSections["available_screen_space"] =
            window.screen.availWidth + "x" + window.screen.availHeight;
        }

        infoSections["browser_window_size"] =
          window.innerWidth + "x" + window.innerHeight;
        infoSections["device_pixel ratio"] = window.devicePixelRatio;
      }
      this.ua_info = infoSections;
    },
    async getUserInfo() {
      this.getDeviceInfo();
      var now = Math.round(+new Date() / 1000);
      var data = {
        last_visit: now,
        geo_data: this.geo_data,
        ua_info: this.ua_info,
      };
      var user = firebase.auth().currentUser;

      if (user && user.uid && user.user_id) {
        firebase
          .firestore()
          .collection("users")
          .doc(user.uid)
          .update(data)
          .then(() => {
            /* eslint-disable no-console */
          });
      }
    },
    applyPromocode() {
      this.isLoading = true;
      fetch(
        this.api_base +
          "/promocode/check/?name=" +
          this.promocode_value.toUpperCase()
      )
        .then((response) => {
          return response.json();
        })
        .then(async (data) => {
          /* eslint-disable no-console */
          console.log(data);
          if (data.valid == false) {
            this.promocode_result.class = "error";
            this.promocode_result.text =
              "Sorry, this promocode doesn't  exist or isn't valid anymore";
          }
          if (data.valid == true) {
            this.promocode_result.class = "success";
            this.promocode_result.text =
              "Sorry, this promocode doesn't  exist or isn't valid anymore";

            //Check if user applied this promocode before
            if (
              !this.user.profile.promocodes ||
              (this.user.profile.promocodes &&
                this.user.profile.promocodes[0] &&
                this.user.profile.promocodes[0] !== this.promocode_value)
            ) {
              var newData = {
                promocodes: [this.promocode_value.toUpperCase()],
              };
              if (this.user.profile.promocodes) {
                newData = {
                  promocodes: this.user.profile.promocodes,
                };
                newData.promocodes.unshift(this.promocode_value.toUpperCase());
              }
              var uid = firebase.auth().currentUser.uid;
              var db = firebase.firestore();
              await db
                .collection("users")
                .doc(uid)
                .update(newData)
                .then(() => {
                  this.promocode_result.class = "success";
                  this.promocode_result.text =
                    "Success! Promocode will be applied shortly";
                })
                .catch((error) => {
                  this.promocode_result.class = "error";
                  this.promocode_result.text = error.message;
                });
            }
            setInterval(() => {
              this.prmocode_timer = this.prmocode_timer - 1;
              if (this.prmocode_timer === 0) {
                window.location.reload(true);
              }
            }, 1000);
          }
          this.isLoading = false;
        });
    },
    openPromocodeModal() {
      this.showPromocodeModal = true;
    },
    closeUserMenu: () => {
      this.showUserMenu = false;
    },
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace({
            name: "login",
          });
        });
    },
    fetchProfile() {
      if (this.user.loggedIn == true && this.user.uid) {
        const db = firebase.firestore();
        const usersRef = db.collection("users").doc(this.user.uid);
        usersRef.get().then((docSnapshot) => {
          this.$store.dispatch("fetchProfile", docSnapshot.data());
          this.$store.dispatch("userSellers", docSnapshot.data().sellers);
        });
      }
    },
    changeLang: function() {
      var currentUserId = firebase.auth().currentUser.uid;
      if (currentUserId) {
        var usersRef = firebase
          .firestore()
          .collection("users")
          .doc(currentUserId);
        //Set new  lang in store
        this.user.profile.lang = this.$i18n.locale;
        this.$store.commit("SET_PROFILE", this.user.profile);

        //Set new  lang to db
        var newUserLang = usersRef.update({
          lang: this.$i18n.locale,
        });
      }

      //Set locale of moment
      this.$moment.locale(this.$i18n.locale);
    },
  },
  mounted() {
    this.$nextTick(function() {
      this.$forceUpdate;
      window.setTimeout(() => {
        this.saveLeadSource();
      }, 1500);
    });
    if (this.user.profile) {
      if (this.user.profile.lang) {
        this.$i18n.locale = this.user.profile.lang;
        this.$moment.locale(this.$i18n.locale);
      }
    } else {
      //Set locale of moment
      this.$moment.locale(this.$i18n.locale);
    }
  },
  created() {
    this.fetchProfile();
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        this.$store.commit("SET_LOGGED_IN", true);
        await this.geoLocate();
      } else {
        this.$store.commit("SET_LOGGED_IN", false);
      }
    });
    this.fetchProfile();
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700,900&display=swap&subset=cyrillic-ext");
@import "@/scss/_global.scss";
body.register,
body.login {
  .footer__links {
    a,
    span {
      color: #fff;
    }
  }
}
.footer {
  &__logos {
    display: flex;
    // align-items:center;
    justify-content: center;
    img {
      margin: 10px 15px;
    }
  }
  &__links {
    padding: 30px 15px;
    text-align: right;
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
    li {
      list-style: none;
      margin: 5px 15px;
      @media (max-width: 768px) {
        margin: 10px 15px;
      }
      span {
        color: #999;
        font-size: 13px;
        text-decoration: none;
        margin-right: 5px;
      }
      a {
        color: #999;
        font-size: 13px;
        text-decoration: none;
        @media (max-width: 768px) {
          font-size: 18px;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.header {
  background-color: #304366;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  justify-content: space-between;

  @media (max-width: 1200px) {
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    z-index: 300;
  }

  .logo {
    display: inline-flex;
    align-items: center;

    img {
      height: 28px;
      margin-right: 10px;
    }
  }

  &.justify-content-center {
    justify-content: center;
  }

  &__menu-toggle {
    position: relative;
    margin-right: 20px;
    height: 30px;
    width: 32px;
    cursor: pointer;
    opacity: 0.8;
    transition: 0.3s;

    @media (min-width: 1025px) {
      display: none;
    }

    &:hover {
      opacity: 1;
      transition: 0.3s;
    }

    i {
      display: block;
      height: 3px;
      background-color: #fff;
      width: 32px;
      position: absolute;
      left: 0px;
      transition: 0.3s;

      &:nth-child(1) {
        top: 3px;
        width: 14px;
      }

      &:nth-child(2) {
        top: 13px;
        width: 32px;
      }

      &:nth-child(3) {
        top: 24px;
        width: 14px;
      }
    }

    &.menu-opened {
      i {
        background-color: #fdbc79;

        &:nth-child(1) {
          left: 18px;
          transition: 0.3s;
        }

        &:nth-child(3) {
          left: 18px;
          transition: 0.3s;
        }
      }
    }
  }

  &__logo {
    display: flex;
    align-items: center;

    a {
      font-size: 20px;
      text-transform: uppercase;
      color: #fff;
      text-decoration: none;
      font-weight: 300;

      &:visited {
        color: #fff;
      }

      strong {
        font-weight: bold;
      }
    }
  }

  &__controls {
    color: #fff;
    display: flex;
    height: 60px;
    align-items: center;

    .active-plan {
      height: 20px;
      font-size: 14px;
      font-weight: 700;
      color: #fff;
      background-color: green;
      border-radius: 4px;
      display: inline-flex;
      text-transform: uppercase;
      padding: 0px 8px;
      align-items: center;
      letter-spacing: 1px;

      @media (max-width: 767px) {
        display: none;
      }

      &.free {
        background-color: transparent;
      }
    }

    .user-menu {
      position: absolute;
      top: calc(100% + 16px);
      right: 0px;
      background-color: #fff;
      //min-height: 100px;
      border-radius: 8px;
      box-shadow: 0px 10px 30px 0px rgba(#000, 0.1);
      font-size: 30px;
      padding: 5px 0px;
      min-width: 200px;
      width: 100%;
      z-index: 20;

      & > svg {
        position: absolute;
        right: 40px;
        top: -18px;
        transform: scaleY(-1);
      }

      .item {
        &.logout {
          border-top: 1px solid #e7e7e7;
        }

        a {
          display: flex;
          color: #424242;
          text-decoration: none;
          padding: 15px 15px;
          align-items: center;
          font-size: 16px;

          svg {
            margin-right: 20px;
          }

          &:hover {
            background-color: rgba(#1ca5fc, 0.1);
          }
        }
      }
    }

    .user-account {
      margin-left: 20px;
      position: relative;

      @media (max-width: 767px) {
        margin-right: -15px;
      }

      .info {
        transition: 0.3s;
        align-items: center;
        display: flex;
        padding: 0px 10px;
        height: 60px;

        & > span {
          @media (max-width: 767px) {
            display: none;
          }
        }

        &:hover {
          background-color: rgba(#fff, 0.1);
          cursor: pointer;
          transition: 0.3s;
        }

        svg {
          margin-left: 10px;

          @media (max-width: 1200px) {
            margin-left: 5px;
          }
        }
      }

      .identity {
        height: 42px;
        width: 42px;
        align-items: center;
        display: flex;
        justify-content: center;
        background: rgb(81, 98, 128);
        background: linear-gradient(
          360deg,
          rgba(81, 98, 128, 1) 0%,
          rgba(104, 121, 151, 1) 100%
        );
        border-radius: 50%;
        text-transform: uppercase;
        font-size: 28px;
        font-weight: 300;
        margin-left: 15px;

        @media (max-width: 767px) {
          padding-left: 0px;
          margin-left: 0px;
        }
      }
    }
  }

  &__menu {
    @media (max-width: 1200px) {
      position: fixed;
      left: 0px;
      top: 60px;
      background: rgba(#314465, 0.95);
      display: flex;
      flex-direction: column;
      width: 320px;
      max-width: calc(100vw - 50px);
      bottom: 0px;
      z-index: 300;
      transform: translateX(-100%);
      transition: 0.3s;
      backdrop-filter: blur(6px);

      &.menu-opened {
        transition: 0.3s;
        transform: translateX(0);
      }
    }

    a {
      color: #fff;
      font-size: 15px;
      text-decoration: none;
      opacity: 0.6;
      transition: 0.3s;
      display: inline-flex;
      height: 60px;
      padding: 10px;
      align-items: center;
      justify-content: center;
      position: relative;
      box-sizing: border-box;
      margin: 0px 10px;

      @media (max-width: 1200px) {
        justify-content: flex-start;
        margin: 0px;
        padding-left: 20px;
        padding-right: 20px;
      }

      svg {
        margin-right: 8px;
      }

      &.router-link-exact-active {
        opacity: 1;

        @media (max-width: 1200px) {
          background: rgba(#fff, 0.1);
        }

        &::after {
          display: block;
          content: "";
          height: 4px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          position: absolute;
          background-color: #febc73;

          @media (max-width: 1200px) {
            display: none;
          }
        }
      }

      &:hover {
        transition: 0.3s;
        opacity: 1;
      }
    }
  }
}
.modal {
  .message {
    &.error {
      background: rgba(#f00, 0.15);
      color: #f00;
      border-radius: 8px;
      padding: 5px 8px;
      line-height: 1.2;
    }
    &.success {
      background: rgba(green, 0.15);
      color: green;
      border-radius: 8px;
      padding: 5px 8px;
      line-height: 1.2;
    }
  }
}
</style>
